import React, {
  MouseEvent,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState
} from 'react'
import styled, { ThemeProvider, css } from 'styled-components'
import {
  darkTheme,
  getUserStateNameFromState,
  getUserIntervalNameFromInterval,
  getNextSnapshotEffect,
  getSnapshotEffectName,
  inputPlaceholder
} from './Helpers'
import { actions as realtimeCallActions } from '../reducers/realtime/call'
import { actions as uiActions } from '../reducers/ui'
import {
  actions as userActions,
  getCurrentUser,
  getNextUserState,
  getTeamState,
  getNextUserCallLayout,
  getShowOffline,
  getSelectedTeamId,
  getCallVideo,
  getCallMute
} from '../reducers/user'
import { getNextAllowedInterval } from '../reducers/teams'
import { actions as settingAction } from '../reducers/settings'
import { actions as profileAction } from '../reducers/user'
import { getSelectedTeamPermissions } from '../reducers/shared'
import NotificationIcon from '../assets/img/notification.svg'
import NotificationIconWithBubble from '../assets/img/notification-with-bubble.svg'
import VisibleHideIcon from '../assets/img/visibility-hide.svg'
import VisibleShowIcon from '../assets/img/visibility-show.svg'
import StatusMessageIcon from '../assets/img/status-message.svg'
import EndCallIcon from '../assets/img/call-leave.svg'
import ScreenShareIcon from '../assets/img/screenshare.svg'
import VideoShowIcon from '../assets/img/video-show.svg'
import VideoHideIcon from '../assets/img/video-hide.svg'
import MuteIcon from '../assets/img/icon.mute.svg'
import UnmuteIcon from '../assets/img/icon.unmute.svg'
import SidebarOpen from '../assets/img/sidebar_open.svg'
import SidebarClose from '../assets/img/sidebar_close.svg'
import EffectIcon from '../assets/img/effect.svg'
import DefaultBackground from '../assets/img/background.png'

import { actions as sessionActions } from '../reducers/session'
import { Tooltip } from 'react-tippy'
import 'react-tippy/dist/tippy.css'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../store'
import { UserAvailabilityState } from '../constants/user'
import { getLayoutName } from '../utils/layout'
import { fetchNotifications } from '../services/api/library/notifications'
import {
  getNotificationCount,
  actions as notificationActions
} from '../reducers/notification'
import NotificationBar from './NotificationBar'
import Icon from './icons/Icon'
import { requestCount } from '../services/api/library/http'
import {
  AVAILABILITY_ICONS,
  EFFECT_ICONS,
  INTERVAL_ICONS,
  LAYOUT_ICONS
} from './icons/constants'
import { SocketContext } from '../contexts/socket'
import { CALL_STATE } from '../constants/call'
import { isElectron } from '../utils/env'
import { useHistory } from 'react-router-dom'
import {
  changeEffect,
  startScreenShare,
  startScreenShareWithSourceId,
  stopScreenShare,
  switchCamera,
  switchMic,
  toggleAudio,
  toggleVideo
} from '../utils/call'
import { TrackContext } from '../contexts/stream'
import { CHANNEL_CLIENT_TEAM } from '../constants/socket'
import { JmtLocalTrack } from 'lib-jitsi-meet'
import { Setting } from '../interfaces/setting'
import { capitalize } from 'lodash'
import BackgroundEffects from './BackgroundEffects'
import { TVirtualBackground } from '../services/video/constants'
import { DevicesContext } from '../contexts/devices'

const packageJson = require('../../package.json')

interface SideBarProps {}

interface IconButtonProps {
  expanded?: boolean
  isActive?: boolean
  animate?: boolean
}

interface ContainerProps {
  expanded: boolean
}

const Container = styled.section<ContainerProps>`
  background: #252525;
  box-sizing: border-box;
  min-height: 100%;
  overflow-x: auto;
  position: relative;
  transition: width 200ms ease-in-out;
  user-select: none;
  z-index: 40;
  min-width: 64px;
  overflow: hidden; /* Hide scrollbars */

  ${(props) =>
    props.expanded &&
    css`
      overflow-y: auto;
      width: 365px;
    `}
`

const IconList = styled.ul<ContainerProps>`
  padding: 0 0 0 0;
  margin-top: 0;
  white-space: nowrap;
  li {
    display: block;
    list-style: none;
    overflow: hidden;
    position: relative;

    ${(props) =>
    !props.expanded &&
      css`
        width: 64px;
      `}
  }
`

const StyledMessage = styled.p`
  font-family: 'DM Sans';
  font-size: 16px;
  font-weight: 700;
  margin: auto 0;
  text-align: left;
`

const IconButton: React.FC<React.PropsWithChildren<any>> = ({
  children,
  isActive,
  expanded,
  icon,
  label,
  message,
  iconStyle = {},
  onClick
}: {
  children?: any
  isActive: boolean
  expanded: boolean
  icon: string
  label: string
  labelBackground?: boolean
  message: string
  iconStyle?: any
  onClick: () => void
}) => {
  const content = children ?? <StyledMessage>{message}</StyledMessage>
  return (
    <StyledIconButton expanded isActive={isActive} onClick={onClick}>
      <Icon icon={icon} label={label} iconStyle={iconStyle} />
      {expanded ? content : <></>}
    </StyledIconButton>
  )
}

const ToggleButton: React.FC<React.PropsWithChildren<any>> = ({
  children,
  isActive,
  expanded,
  icon,
  label,
  message,
  onClick
}: {
  children?: any
  isActive: boolean
  expanded: boolean
  icon: string
  label: string
  labelBackground?: boolean
  message: string
  onClick: () => void
}) => {
  const content = children ?? <StyledMessage>{message}</StyledMessage>
  return (
    <StyledIconButton expanded style={{}} isActive={isActive} onClick={onClick}>
      <Icon icon={icon} label={label} />
      {expanded ? content : <></>}
    </StyledIconButton>
  )
}

const StyledIconButton = styled.button<IconButtonProps>`
  ${(props) =>
    props.isActive && props.isActive === true
      ? css`
          background: #acacac;
        `
      : css`
          background: none;
        `}
  ${(props) =>
    props.animate && props.animate === true
      ? css`
          animation: blink 1s linear infinite;
        `
      : css`
          animation: none;
        `}
  border: 0;
  color: white;
  cursor: pointer;
  display: flex;
  padding: 0 0 0 0;
  margin: 0 auto;
  ${(props) =>
    props.expanded
      ? css`
          justify-content: start;
        `
      : css`
          justify-content: center;
        `}
  font-weight: bold;
  height: 64px;
  transition: background 200ms ease-in-out;
  width: 100%;

  @keyframes blink {
    0% {
      opacity: 0;
    }
    25% {
      opacity: 0.5;
    }
    75% {
      opacity: 1;
    }
    100% {
      opacity: 0.5;
    }
  }
  &:hover {
    background-color: #3264ff;
    opacity: 1;
  }
`

const StatusMessageInput = styled.input`
  background: none;
  border: none;
  color: white;
  font-size: 14px;
  font-weight: bold;
  height: 100%;
  line-height: 64x;
  width: calc(100% - 64px);
  ${inputPlaceholder`
    color: white;
    opacity: 0.5;
  `}
`

const LinksList = styled.ul<ContainerProps>`
  margin: 0;
  opacity: 0;
  overflow: hidden;
  padding: 0;
  position: relative;
  text-align: left;
  transition: opacity 200ms ease-in-out;
  white-space: nowrap;
  width: 0;

  ${(props) =>
    props.expanded &&
    css`
      opacity: 1;
      width: auto;
    `}
  li {
    display: block;
    list-style: none;
  }
`

const Link = styled.button<{maxWidth?: number}>`
  background: none;
  border: none;
  color: white;
  cursor: pointer;
  display: block;
  font-size: 12px;
  font-weight: bold;
  height: 40px;
  line-height: 40px;
  padding: 0 0 0 22px;
  text-align: left;
  text-decoration: none;
  width: 100%;
  text-transform: capitalize;
  ${(props) =>
    props.disabled
      ? css`
          color: grey;
        `
      : css`
          transition: background 200ms ease-in-out;
          &:hover {
            background: #3264ff;
            opacity: 1;
          }
        `}
  ${(props) =>
    props.maxWidth &&
    css`
      text-overflow: ellipsis;
      text-wrap: nowrap;
      overflow: hidden;
      max-width: ${props.maxWidth}px;
    `}
`

const tooltipOptions = {
  position: 'right',
  delay: 300,
  hideOnClick: false,
  animation: 'fade',
  sticky: true,
  arrow: true
}

const SideBar: React.FC<SideBarProps> = () => {
  const expanded = useSelector((state: RootState) => state.ui.sideBarExpanded)
  const selectedTeamId = useSelector((state: RootState) =>
    getSelectedTeamId(state)
  )
  const [showNotification, setShowNotification] = useState<boolean>(false)
  const notificationCount = useSelector((state: RootState) =>
    getNotificationCount(state, selectedTeamId)
  )
  const user = useSelector((state: RootState) => getCurrentUser(state))
  const teamState = useSelector((state: RootState) => getTeamState(state))
  const call = user.teamState[user.selectedTeam]
  const callVideo = useSelector((state: RootState) => getCallVideo(state))
  const callMute = useSelector((state: RootState) => getCallMute(state))
  const teamPermissions = useSelector((state: RootState) =>
    getSelectedTeamPermissions(state)
  )
  const showOffline = useSelector((state: RootState) => getShowOffline(state))
  const settings: Setting = useSelector((state: RootState) => state.settings)
  const statusMessageInput = useRef()
  const [statusMessage, setStatusMessage] = useState(teamState.statusMessage)
  const [selectedCamera, setSelectedCamera] = useState<string>()
  const [selectedMic, setSelectedMic] = useState<string>()
  const [screen, setScreen] = useState<{
    isActive: boolean
    userId: number
    track: JmtLocalTrack | null
  }>({
    isActive: false,
    userId: 0,
    track: null
  })
  const [isOpenEffectsTooltips, setIsOpenEffectsTooltips] = useState(false)
  const { serverChannel, clientChannels } = useContext(SocketContext)
  const { tracks, setTracks } = useContext(TrackContext)
  const { devices } = useContext(DevicesContext)
  const teamChannel = clientChannels[`${CHANNEL_CLIENT_TEAM}:${selectedTeamId}`]
  const history = useHistory()
  // dispatches
  const dispatch = useDispatch()

  const onCameraSelected = (value: string) => {
    dispatch(
      settingAction.updateSettingState({
        name: 'camera',
        value
      })
    )

    if (call.callState === CALL_STATE.IN_CALL) {
      switchCamera({ ...settings, camera: value }, setTracks)
        .then(() => {
          // eslint-disable-next-line
          console.log('debug: switch camera success')
        })
        .catch((e) => {
          // eslint-disable-next-line
          console.log('debug: switch camera fail', e)
        })
    }
  }

  const onMicSelected = (value: string) => {
    dispatch(
      settingAction.updateSettingState({
        name: 'mic',
        value
      })
    )

    if (call.callState === CALL_STATE.IN_CALL) {
      switchMic({ ...settings, mic: value }, setTracks).catch((err) => {
        // eslint-disable-next-line
        console.log('debug: switch mic fail', err)
      })
    }
  }

  const onScreenShare = async () => {
    if (screen?.isActive) {
      if (screen.userId === user.id) {
        await stopScreenShare(setTracks)
        setScreen({ isActive: true, userId: 0, track: null })
      }
      return
    }

    if (isElectron()) {
      // @ts-ignore
      window.ipcRender.screenshare()
      return
    }

    const userId = await startScreenShare(setTracks)
    setScreen({ isActive: true, userId, track: null })
  }

  const onSetSource = useCallback((sourceId: string) => {
    // @ts-ignore
    window.ipcRender.screenshareSuccess(() => {})
    startScreenShareWithSourceId(sourceId).then(({ userId, screenTrack }) => {
      setScreen({ isActive: true, userId, track: screenTrack })
    })
  }, [])

  const onChangeEffect = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    setIsOpenEffectsTooltips(false)
    const { name, value } = e.currentTarget
    if (name === 'background') {
      dispatch(
        settingAction.changeBackgroundEffect({
          name: value as TVirtualBackground,
          value: ''
        })
      )
      return
    }
    dispatch(
      settingAction.changeBackgroundEffect({
        name: 'image',
        value
      })
    )
  }

  useEffect(() => {
    if (!isElectron()) {
      return () => {}
    }
    // @ts-ignore
    window.ipcRender?.on('SET_SOURCE', onSetSource)
    return () => {
      // @ts-ignore
      window.ipRender?.off('SET_SOURCE', onSetSource)
    }
  }, [onSetSource])

  useEffect(() => {
    if (screen.track) {
      setTracks((prevTracks) => {
        const uTracks = prevTracks ? prevTracks[screen.userId] ?? [] : []
        return {
          ...prevTracks,
          [screen.userId]: [
            ...uTracks.filter((t) => t.track?.getVideoType() !== 'desktop'),
            { track: screen.track, muted: false }
          ]
        }
      })
    }
  }, [screen.track])

  useEffect(() => {
    if (!tracks) {
      setScreen({
        isActive: false,
        userId: 0,
        track: null
      })
      return
    }

    for (const uId of Object.keys(tracks)) {
      const track = tracks[uId].find(
        (t) =>
          t.track && t.track.getVideoType() === 'desktop' && !t.track.isMuted()
      )
      if (track) {
        setScreen({
          isActive: true,
          userId: Number(uId),
          track: null
        })
        break
      }
      setScreen({
        isActive: false,
        userId: 0,
        track: null
      })
    }
  }, [tracks, screen.isActive])

  useEffect(() => {
    changeEffect(settings.background, settings.backgroundSource, setTracks)
  }, [settings.background, settings.backgroundSource])

  useEffect(() => {
    if (call.callState !== CALL_STATE.IN_CALL) {
      return
    }
    toggleAudio(callMute)
      .then(() => {
        // eslint-disable-next-line
        console.log('debug: audio toggled')
      })
      .catch(() => {
        // eslint-disable-next-line
        console.log('debug: audio toggle fail')
      })
  }, [callMute])

  useEffect(() => {
    if (call.callState !== CALL_STATE.IN_CALL) {
      return
    }
    toggleVideo(callVideo)
      .then(() => {
        // eslint-disable-next-line
        console.log('debug: video toggled')
      })
      .catch(() => {
        // eslint-disable-next-line
        console.log('debug: video toggle fail')
      })
  }, [callVideo])

  useEffect(() => {
    const cameraTrack =
      (tracks &&
        (tracks[user.id]?.find((t) => t.track?.getVideoType() === 'camera')
          ?.track as unknown as JmtLocalTrack)) ||
      // eslint-disable-next-line no-undefined
      undefined ||
      null
    setSelectedCamera(cameraTrack?.getDeviceId() || settings.camera)

    const audioTrack =
      (tracks &&
        (tracks[user.id]?.find((t) => t.track?.getType() === 'audio')
          ?.track as unknown as JmtLocalTrack)) ||
      // eslint-disable-next-line no-undefined
      undefined ||
      null
    setSelectedMic(audioTrack?.getDeviceId() || settings.camera)
  }, [tracks])

  useEffect(() => {
    if (call.callState !== CALL_STATE.IN_CALL) {
      return
    }
    const audioTrack =
      (tracks &&
        (tracks[user.id]?.find((t) => t.track?.getType() === 'audio')
          ?.track as unknown as JmtLocalTrack)) ||
      // eslint-disable-next-line no-undefined
      undefined ||
      null

    if (
      !devices.mics.some((m) => m.deviceId === audioTrack?.getDeviceId())
    ) {
      onMicSelected(
        devices.mics.find((m) =>
          m.label.toLocaleLowerCase().includes('default')
        )?.deviceId ?? devices.mics[0].deviceId
      )
    }

    const videoTrack =
      (tracks &&
        (tracks[user.id]?.find((t) => t.track?.getVideoType() === 'camera')
          ?.track as unknown as JmtLocalTrack)) ||
      // eslint-disable-next-line no-undefined
      undefined ||
      null

    if (
      !devices.cameras.some(
        (m) => m.deviceId === videoTrack?.getDeviceId()
      )
    ) {
      onCameraSelected(
        devices.cameras.find((m) =>
          m.label.toLocaleLowerCase().includes('default')
        )?.deviceId ?? devices.cameras[0].deviceId
      )
    }
  }, [devices])

  useEffect(() => {
    if (selectedTeamId) {
      fetchNotifications(selectedTeamId, false)
        .then((res) => {
          const { data } = res
          dispatch(
            notificationActions.setNotificationCount(
              selectedTeamId,
              data.filter((datum) => !datum.read).length
            )
          )
        })
        .catch(() => {
          return dispatch(
            notificationActions.updateSnackbar({
              show: true,
              status: 'error',
              message: 'Failed to fetch notification, please try again later.',
              timeout: 3000
            })
          )
        })
    }
  }, [])

  const updateInterval = useCallback(() => {
    if (!serverChannel) {
      return
    }
    dispatch(
      userActions.changeInterval(
        user.selectedTeam,
        getNextAllowedInterval(teamState.interval, teamPermissions.intervals),
        serverChannel
      )
    )
  }, [
    user.selectedTeam,
    teamState.interval,
    teamPermissions.intervals,
    serverChannel
  ])

  useEffect(() => {
    const exist = teamPermissions.intervals.find(
      (interval: { id: any }) => teamState.interval.id === interval.id
    )
    if (exist) {
      return
    }
    updateInterval()
  }, [teamPermissions.intervals, teamState.interval])

  if (isElectron()) {
    // @ts-ignore
    window.ipcRender.onScreenpickerClosed(() => {
      // setIsScreenSharing(false)
    })
  }

  const secondaryMenuStyle = {
    fontFamily: 'DM Sans',
    fontSize: '16px',
    fontWeight: '400'
  }

  useEffect(() => {
    setIsOpenEffectsTooltips(false)
  }, [user.callState])

  const backgrounds: Array<string> = [
    DefaultBackground,
    ...(settings.backgroundSources ?? [])
  ]

  return (
    <ThemeProvider theme={darkTheme}>
      {showNotification ? (
        <NotificationBar
          onBack={() => {
            setShowNotification(false)
          }}
        />
      ) : (
        <Container expanded={expanded}>
          <IconList expanded={expanded}>
            <li>
              {/* @ts-ignore */}
              <Tooltip title='Sidebar' {...tooltipOptions} disabled={expanded}>
                <ToggleButton
                  expanded={expanded}
                  icon={expanded ? SidebarClose : SidebarOpen}
                  message={'Close'}
                  onClick={() => {
                    dispatch(uiActions.toggleSideBar())
                  }}
                />
              </Tooltip>
            </li>
            {call.callState !== CALL_STATE.IN_CALL &&
              [CALL_STATE.JOINING_CALL, CALL_STATE.IN_CALL].indexOf(
                teamState.callState
              ) < 0 && (
              <React.Fragment>
                <li>
                  {/* @ts-ignore */}
                  <Tooltip
                    title={`Notifications`}
                    {...tooltipOptions}
                    disabled={expanded}>
                    <IconButton
                      expanded={expanded}
                      label={notificationCount > 0 ? notificationCount : ''}
                      labelBackground={notificationCount > 0}
                      icon={
                        notificationCount > 0
                          ? NotificationIconWithBubble
                          : NotificationIcon
                      }
                      message={'View Notifications'}
                      onClick={() => {
                        if (!showNotification && !expanded) {
                          dispatch(uiActions.toggleSideBar())
                        }
                        setShowNotification(!showNotification)
                      }}
                    />
                  </Tooltip>
                </li>
                <li>
                  {/* @ts-ignore */}
                  <Tooltip
                    html={
                      <div>
                        Availability:
                        <b> {getUserStateNameFromState(teamState.state)}</b>
                      </div>
                    }
                    {...tooltipOptions}
                    disabled={expanded}>
                    <IconButton
                      expanded={expanded}
                      disabled={call.callState === CALL_STATE.IN_CALL}
                      message={getUserStateNameFromState(
                        teamState.state || UserAvailabilityState.AVAILABLE
                      )}
                      icon={
                        AVAILABILITY_ICONS[
                          String(teamState.state) ||
                            UserAvailabilityState.AVAILABLE
                        ]
                      }
                      onClick={() => {
                        if (teamChannel) {
                          dispatch(
                            userActions.changeState(
                              user.selectedTeam,
                              getNextUserState(teamState.state),
                              teamChannel
                            )
                          )
                        }
                      }}
                    />
                  </Tooltip>
                </li>
                {teamPermissions.intervals?.length > 1 && (
                  <li>
                    {/* @ts-ignore */}
                    <Tooltip
                      html={
                        <div>
                          Snapshot Interval:
                          <b>
                            {' '}
                            {getUserIntervalNameFromInterval(
                              teamState.interval
                            )}
                          </b>
                        </div>
                      }
                      {...tooltipOptions}
                      disabled={expanded}>
                      <IconButton
                        expanded={expanded}
                        icon={INTERVAL_ICONS[teamState.interval.second]}
                        message={getUserIntervalNameFromInterval(
                          teamState.interval
                        )}
                        iconStyle={{
                          paddingRight: '1.5px'
                        }}
                        onClick={() => {
                          updateInterval()
                        }}
                      />
                    </Tooltip>
                  </li>
                )}
                <li>
                  {/* @ts-ignore */}
                  <Tooltip
                    html={
                      <div>
                        User Visibility:
                        <b> {showOffline ? 'show' : 'hide'}</b>
                      </div>
                    }
                    {...tooltipOptions}
                    disabled={expanded}>
                    <IconButton
                      expanded={expanded}
                      icon={showOffline ? VisibleHideIcon : VisibleShowIcon}
                      message={showOffline ? 'Hide Offline' : 'Show Offline'}
                      onClick={() => {
                        dispatch(userActions.toggleShowOffline())
                      }}
                    />
                  </Tooltip>
                </li>
                {teamPermissions.snapshotEffects && (
                  <li>
                    {/* @ts-ignore */}
                    <Tooltip
                      html={
                        <div>
                          Snapshot Effect:
                          <b>
                            {' '}
                            {getSnapshotEffectName(teamState.snapshotEffect)}
                          </b>
                        </div>
                      }
                      {...tooltipOptions}
                      disabled={expanded}>
                      <IconButton
                        expanded={expanded}
                        icon={EFFECT_ICONS[teamState.snapshotEffect]}
                        message={getSnapshotEffectName(
                          teamState.snapshotEffect
                        )}
                        onClick={() => {
                          if (!serverChannel) {
                            return
                          }
                          dispatch(
                            userActions.changeSnapshotEffect(
                              user.selectedTeam,
                              getNextSnapshotEffect(teamState.snapshotEffect),
                              serverChannel
                            )
                          )
                        }}
                      />
                    </Tooltip>
                  </li>
                )}
                <li>
                  {/* @ts-ignore */}
                  <Tooltip
                    title={'Enter Status Message'}
                    {...tooltipOptions}
                    disabled={expanded}>
                    <IconButton
                      expanded={expanded}
                      icon={StatusMessageIcon}
                      message={''}
                      onClick={() => {
                        dispatch(uiActions.expandSideBar())
                        setTimeout(
                          // @ts-ignore
                          () => statusMessageInput.current.focus(),
                          300
                        )
                      }}>
                      <StatusMessageInput
                        style={{
                          fontFamily: 'DM Sans',
                          fontSize: '16px'
                        }}
                        type='text'
                        maxLength={50}
                        value={statusMessage ?? ''}
                        placeholder='Enter Status Message'
                        onKeyDown={(event) => {
                          if (event.key === 'Enter' && teamChannel) {
                            event.preventDefault()
                            dispatch(
                              userActions.changeStatusMessage(
                                user.selectedTeam,
                                // @ts-ignore
                                event.target.value,
                                teamChannel
                              )
                            )
                            dispatch(uiActions.collapseSideBar())
                          }
                        }}
                        onChange={(event) => {
                          setStatusMessage(event.target.value)
                        }}
                        // @ts-ignore
                        ref={statusMessageInput}
                      />
                    </IconButton>
                  </Tooltip>
                </li>
              </React.Fragment>
            )}
            {[CALL_STATE.JOINING_CALL, CALL_STATE.IN_CALL].indexOf(
              teamState.callState
            ) > -1 && (
              <React.Fragment>
                <li>
                  {/* @ts-ignore */}
                  <Tooltip
                    title='End Call'
                    {...tooltipOptions}
                    disabled={expanded}>
                    <IconButton
                      expanded={expanded}
                      onClick={() => {
                        dispatch(
                          realtimeCallActions.sendLeave(
                            clientChannels,
                            setTracks
                          )
                        )
                      }}
                      message={'End Call'}
                      icon={EndCallIcon}
                    />
                  </Tooltip>
                </li>
                <li>
                  {/* @ts-ignore */}
                  <Tooltip
                    title='Screenshare'
                    {...tooltipOptions}
                    disabled={expanded}>
                    <IconButton
                      expanded={expanded}
                      animate={!!screen?.userId}
                      icon={ScreenShareIcon}
                      message={'Screenshare'}
                      isActive={!!screen?.userId}
                      onClick={onScreenShare}
                    />
                  </Tooltip>
                </li>
                <li>
                  {/* @ts-ignore */}
                  <Tooltip
                    title={callVideo ? 'Hide Video' : 'Show Video'}
                    {...tooltipOptions}
                    disabled={expanded}>
                    <IconButton
                      expanded={expanded}
                      icon={callVideo ? VideoHideIcon : VideoShowIcon}
                      message={callVideo ? 'Hide Video' : 'Show Video'}
                      onClick={() => {
                        dispatch(userActions.toggleCallVideo(!callVideo))
                      }}
                    />
                  </Tooltip>
                </li>
                <li>
                  {/* @ts-ignore */}
                  <Tooltip
                    title='Effects'
                    open={isOpenEffectsTooltips}
                    disabled={expanded}
                    html={
                      <BackgroundEffects
                        backgrounds={backgrounds}
                        onChange={onChangeEffect}
                      />
                    }
                    interactive
                    trigger='click'
                    {...tooltipOptions}>
                    <IconButton
                      expanded={expanded}
                      icon={EffectIcon}
                      message='Effects'
                      onClick={() => setIsOpenEffectsTooltips((prev) => !prev)}
                    />
                    <div
                      style={{
                        margin: 'auto 20px',
                        display: expanded ? 'block' : 'none'
                      }}>
                      <BackgroundEffects
                        backgrounds={backgrounds}
                        onChange={onChangeEffect}
                      />
                    </div>
                  </Tooltip>
                </li>
                <li>
                  {/* @ts-ignore */}
                  <Tooltip
                    title={callMute ? 'Unmute' : 'Mute'}
                    {...tooltipOptions}
                    disabled={expanded}>
                    <IconButton
                      expanded={expanded}
                      icon={callMute ? MuteIcon : UnmuteIcon}
                      message={callMute ? 'Unmute' : 'Mute'}
                      onClick={() => {
                        dispatch(userActions.toggleCallMute(!callMute))
                      }}
                    />
                  </Tooltip>
                </li>
                <li>
                  {/* @ts-ignore */}
                  <Tooltip
                    title=''
                    html={
                      <div>
                        Call Layout
                        <b>
                          {' '}
                          {getLayoutName(
                            user.teamState[user.selectedTeam].layout
                          )}
                        </b>
                      </div>
                    }
                    {...tooltipOptions}
                    disabled={expanded}>
                    <IconButton
                      expanded={expanded}
                      icon={
                        LAYOUT_ICONS[user.teamState[user.selectedTeam].layout]
                      }
                      message={getLayoutName(
                        user.teamState[user.selectedTeam].layout
                      )}
                      onClick={() => {
                        dispatch(
                          userActions.changeLayout(
                            user.selectedTeam,
                            getNextUserCallLayout(
                              user.teamState[user.selectedTeam].layout
                            )
                          )
                        )
                      }}
                    />
                  </Tooltip>
                </li>
              </React.Fragment>
            )}
          </IconList>
          <LinksList expanded={expanded}>
            {call.callState === CALL_STATE.IN_CALL && (
              <li
                style={{
                  padding: '11px 22px'
                }}>
                {/* @ts-ignore */}
                <Tooltip title='Camera' disabled={expanded}>
                  <div className='hardware-setup-selector'>
                    <div className='skinned-wrapper-sidebar'>
                      <div className='skinned-text'>
                        <Link>
                          {devices.cameras.find(
                            (dev) => dev.deviceId === selectedCamera
                          )?.label ||
                            devices.cameras.find((dev) => dev.deviceId === 'default')
                              ?.label ||
                            'default'}
                        </Link>
                      </div>
                      <select
                        // placeholder='camera'
                        name='camera'
                        className='skinned-select'
                        onChange={(e) => {
                          onCameraSelected(e.currentTarget.value)
                        }}
                        value={selectedCamera}>
                        {devices.cameras
                          .map((device) => ({
                            id: device.deviceId,
                            label: device.label
                          }))
                          .map((device) => {
                            return (
                              <option
                                style={{ color: '#ffffff' }}
                                value={device.id}
                                key={device.id}>
                                {capitalize(device.label)}
                              </option>
                            )
                          })}
                      </select>
                    </div>
                  </div>
                </Tooltip>
              </li>
            )}
            {call.callState === CALL_STATE.IN_CALL && (
              <li
                style={{
                  padding: '11px 22px'
                }}>
                {/* @ts-ignore */}
                <Tooltip title='Microphone' disabled={expanded}>
                  <div className='hardware-setup-selector'>
                    <div className='skinned-wrapper-sidebar'>
                      <div className='skinned-text'>
                        <Link maxWidth={260}>
                          {devices.mics.find((dev) => dev.deviceId === selectedMic)
                            ?.label ||
                            devices.mics.find((dev) => dev.deviceId === 'default')
                              ?.label ||
                            'default'}
                        </Link>
                      </div>
                      <select
                        name='microphone'
                        className='skinned-select'
                        onChange={(e) => {
                          onMicSelected(e.currentTarget.value)
                        }}
                        value={selectedMic}>
                        {devices.mics
                          .map((device) => ({
                            id: device.deviceId,
                            label: device.label
                          }))
                          .map((device) => {
                            return (
                              <option
                                style={{ color: '#ffffff' }}
                                value={device.id}
                                key={device.id}>
                                {device.label}
                              </option>
                            )
                          })}
                      </select>
                    </div>
                  </div>
                </Tooltip>
              </li>
            )}
            <li>
              <Link
                style={secondaryMenuStyle}
                // @ts-ignore
                onClick={() => {
                  if (settings.showSetting) {
                    dispatch(settingAction.close())
                  } else {
                    dispatch(settingAction.open())
                  }
                }}>
                Settings
              </Link>
            </li>
            <li>
              <Link
                style={secondaryMenuStyle}
                disabled={call.callState === CALL_STATE.IN_CALL}
                onClick={() => {
                  if (user.showProfile) {
                    dispatch(profileAction.closeProfile())
                  } else {
                    dispatch(profileAction.openProfile())
                  }
                }}>
                Profile
              </Link>
            </li>
            <li>
              <Link
                style={secondaryMenuStyle}
                disabled={call.callState === CALL_STATE.IN_CALL}
                onClick={() => history.push('/account')}>
                Account
              </Link>
            </li>
            <li>
              <Link
                style={secondaryMenuStyle}
                disabled={call.callState === CALL_STATE.IN_CALL}
                onClick={() =>
                  window.open(
                    `${process.env.REACT_APP_APP_URL}/support`,
                    '_blank'
                  )
                }>
                Support
              </Link>
            </li>
            <li>
              <Link
                style={secondaryMenuStyle}
                disabled={call.callState === CALL_STATE.IN_CALL}
                onClick={() => {
                  dispatch(sessionActions.logout())
                }}>
                Logout
              </Link>
            </li>
            <li>
              <Link style={secondaryMenuStyle} type='button'>
                Version: {packageJson.version}
              </Link>
            </li>
            <li>
              <Link
                style={secondaryMenuStyle}
                onClick={() => {
                  navigator.clipboard
                    .writeText(JSON.stringify(requestCount))
                    .then(() => {
                      // eslint-disable-next-line no-alert
                      alert('request count information copied to clipboard')
                    })
                }}>
                Debug information (click to copy)
              </Link>
            </li>
          </LinksList>
        </Container>
      )}
    </ThemeProvider>
  )
}

export default SideBar
